module.exports = {
	/**
	 * function to validate the registration form inputs
	 * @param {string} name Input name
	 * @param {string} value Input value
	 * @param {object} [data={}] All input values stored in the state
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	validate: (name, value, data = {}) => {
		//eslint-disable-next-line
		const errors = {};
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

		switch (name) {
			case 'email': {
                errors.email = validEmailRegex.test(value) ? '' : 'Email is invalid.';
				if (value === '') errors.email = 'Email is required';
				break;
			}
            case 'phone': {
				errors.phone = '';
				if (value === 'undefined') {
                    errors.phone = 'Phone number is invalid. '
                }
				if (value === '') errors.phone = 'Phone number is required';
				break;
			}
            case 'address': {
				errors.address = '';
				if (value === '') errors.address = 'Address Line 1 is required';
				break;
			}
            case 'city': {
				errors.city = '';
				if (value === '') errors.city = 'City is required';
				break;
			}
            case 'state': {
				errors.state = '';
				if (value === '') errors.state = 'State is required';
				break;
			}
            case 'country_code': {
				errors.country_code = '';
				if (value === '') errors.country_code = 'Country Code is required';
				break;
			}
            case 'zip_code': {
				errors.zip_code = '';
				if (value === '') errors.zip_code = 'Zip Code is required';
				break;
			}
			case 'note': {
				errors.note = '';
				var expression = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{1,}(\.[a-zA-Z]{1,})(\.[a-zA-Z]{1,})?\/[a-zA-Z0-9]{1,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{1,}(\.[a-zA-Z]{1,})(\.[a-zA-Z]{1,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{1,}\.[a-zA-Z0-9]{1,}\.[a-zA-Z0-9]{1,}(\.[a-zA-Z0-9]{1,})?/g;;
            	var regex = new RegExp(expression);
				if (value === '') errors.note = 'Note is required';
				else if (regex.test(value)) {
					errors.note = 'URL not allowed';
				}
				break;
			}
			case 'attachment': {
                errors.attachment = '';
				if (value === '' || value === [] || typeof value === 'undefined') {
                    errors.attachment = 'Attachment is required';
                }
                else if (typeof value !== 'string') {
                    let extension = (value.name).split('.').pop();
                    extension = extension.toLowerCase();
                    let size = value.size;
                    if (extension !== 'pdf' && extension !== 'doc' && extension !== 'docx' && extension !== 'csv' && extension !== 'jpeg' && extension !== 'jpg' && extension !== 'png' && extension !== 'xlsx') {
                        errors.attachment = 'Please choose a valid file format.';
                    } else if (size >= 10000000) {
                        errors.attachment = 'Selected file size exceed the limit. ';
                    }
                }
                break;
            }

			case 'file_type': {
				errors.file_type = '';
				if (value === '') errors.file_type = 'File type is required';
				break;
			}

			case 'document_name': {
				errors.document_name = '';
				if (value === '' && (data.file_type && data.file_type === 'Other')) errors.document_name = 'Document name is required';
				break;
			}

            case 'otp_mobile_number': {
				errors.otp_mobile_number = '';
				if (value === 'undefined') {
                    errors.otp_mobile_number = 'Phone number is invalid. '
                }
				if (value === '') errors.otp_mobile_number = 'Phone number is required';
				break;
			}

			default: {
				errors[name] = '';
				break;
			}
		}
		return errors;
	},

};
