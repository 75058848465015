import { Home } from '@mui/icons-material';
import { ClaimDetails } from '../app/post-auth/claim-details/ClaimDetails';
import { ErrorElement } from '../_components/layout/error-element';

const allRoutes = {
	claim_details: {
		path: '/claim-details',
		name: 'Claim Details',
		element: <ClaimDetails />,
		layout: '/post-auth',
		isMenuItem: true,
		menuIcon: Home,
		module: 'dashboard',
		isModuleMenuItem: true,
		isMainElement: true,
		errorElement: <ErrorElement />,
		menuPermission: 'dashboards',
	},
};

function postAuthRoutes(keyType = null) {
	if (keyType) {
		return allRoutes[keyType];
	}
	return allRoutes;
}

function postAuthMenuRoutes(module = false) {
	return Object.values(allRoutes).filter(function (item) {
		if (!module) {
			return item.isMenuItem === true;
		} else {
			return item.isModuleMenuItem === true && item.module === module
				? {
						...item,
						path: item.path.split('/').pop(),
				  }
				: null;
		}
	});
}

function getPostElementNameByPath(path) {
	if (path) {
		let elementItem = Object.values(allRoutes).filter(function (item) {
			return item.path === path;
		});
		return elementItem.length > 0 ? elementItem[0]['name'] : '';
	}
}

export { postAuthRoutes, postAuthMenuRoutes, getPostElementNameByPath };
