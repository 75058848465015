import { ClaimInfo, Authenticate, Otp, AutoLogout, IsMobile } from '../app/pre-auth';
import { ErrorElement } from '../_components/layout/error-element';

const allRoutes = {
	claim_info: {
		path: '/claim-info',
		name: 'Claim Number',
		element: <ClaimInfo />,
		layout: '/pre-auth',
		isMainElement: true,
		errorElement: <ErrorElement />,
	},
	authenticate: {
		path: '/authenticate',
		name: 'Authenticate',
		element: <Authenticate />,
		layout: '/pre-auth',
		isMainElement: true,
		errorElement: <ErrorElement />,
	},
	otp: {
		path: '/otp',
		name: 'OTP',
		element: <Otp />,
		layout: '/pre-auth',
		isMainElement: true,
		errorElement: <ErrorElement />,
	},
	auto_logout: {
		path: '/session-out',
		name: 'Session Out',
		element: <AutoLogout />,
		layout: '/pre-auth',
		isMainElement: true,
		errorElement: <ErrorElement />,
	},
	is_mobile: {
		path: '/is-mobile',
		name: '',
		element: <IsMobile />,
		layout: '/pre-auth',
		isMainElement: true,
		errorElement: <ErrorElement />,
	},
};

function preAuthRoutes(keyType = null) {
	if (keyType) {
		return allRoutes[keyType];
	}
	return allRoutes;
}

export { preAuthRoutes };
