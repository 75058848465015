import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

function ModalFooterCloseBtn({ text, className, tabIndex, onClose, props }) {

    return (
        <Button className={className} type="button" variant="contained" color="secondary" tabIndex={tabIndex} data-dismiss="modal" onClick={() => onClose()} {...props}>
            {text}
        </Button>
    )
}


ModalFooterCloseBtn.defaultProps = {
    className: "",
    text: "Close",
}


ModalFooterCloseBtn.propTypes = {
    className: PropTypes.string,
    tabIndex: PropTypes.number,
    text: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
}

export { ModalFooterCloseBtn }
