

import React from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import theme from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-dark';

function ErrorElement() {
    let error = useRouteError();
    let message = isRouteErrorResponse(error) ? `${error.status} ${error.statusText}` : (error instanceof Error ? error.message : JSON.stringify(error));
    let stack = error instanceof Error ? error.stack : null;


    return (
        <React.Fragment>
            <h2>Unexpected Application Error!</h2>
            <h3 style={{ fontStyle: "italic" }}>{message}</h3>
            <SyntaxHighlighter wrapLines={true} showLineNumbers={true} style={theme}>{stack}</SyntaxHighlighter>
        </React.Fragment>
    );
}

export { ErrorElement }