import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper, styled, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { Card, CardContent, CardHeader } from "../../../_components/card/";
import { useLocation } from 'react-router-dom';
import { preAuthRoutes } from '../../../routes';
import { useNavigate } from 'react-router';
import { cookieServices } from '../../../_helpers';
import { AppLogout } from '../../../_layouts/post-auth/appLogout/AppLogout';
import { ProgressBar } from "../../../_components/progressbar";
import EditIcon from '@mui/icons-material/Edit';
import { UpdateBeneficiaryModal } from "../../../_components";
import { UpdateBeneficiaryAction } from "../../../redux/actions";
import { ClaimStatusAction } from "../../../redux/actions";
import IconButton from '@mui/material/IconButton';
import { GlobalAction } from '../../../redux/actions';
import { PageLoader } from "../../../_components";

import { AddNoteModal } from '../../../_components/modal/modals/AddNoteModal';
import { UploadAttchmentModal } from '../../../_components/modal/modals/UploadAttchmentModal';
import { GeneralButton } from '../../../_components/controls';

import { AddNoteAction } from '../../../redux/actions';

const StyledTableContainer = styled(({ ...props }) => <TableContainer component={Paper} {...props} />)`
	padding: 0;
	& .MuiTableRow-root {
		&:nth-of-type(odd) {
			background: white;
		}
		&:nth-of-type(even) {
			background: rgba(145, 186, 164, 0.08);
		}

		& .MuiTableCell-root {
			width: 50%;
		}
	}
`;


function ClaimDetails() {

	/** claim_number ssn page */
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { claim_number, beneficiary_id, claim_id } = location.state ?? {};
	/** Initialize and declare state */
	const [data, setData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [progressBarData, setProgressBarData] = useState([]);
	const [beneficiaryData, setBeneficiaryData] = useState([]);
	const [showAddNotesModal, setshowAddNotesModal] = useState(false);
	const [showUploadAttachmentModal, setshowUploadAttachmentModal] = useState(false);
	const [errors, setErrors] = useState([]);

	const [attatchmentData, setAttatchmentData] = useState({'client_code': '', claimant_last_name: ''});


	/** Redux actions and state */
	const { NOTE_errors: noteErrors } = useSelector((state) => state.OtpReducer);
	const addNote = (params) => dispatch(AddNoteAction.addNote(params));


	useEffect(() => {
		setErrors({ ...noteErrors });
	}, [noteErrors])

	/**
	 * function to open/close status Update modal
	 * @return view
	 * @author
	 * @created_at 14 June 2022
	 */
	const openAddNoteModal = (note) => {
		setshowAddNotesModal(!showAddNotesModal);
	}

	/**
	 * function to open/close status Update modal
	 * @return view
	 * @author
	 * @created_at 14 June 2022
	 */
	const openUploadAttchmentModal = (note) => {
		setshowUploadAttachmentModal(!showUploadAttachmentModal);
	}

	/**
	 * function to update Advertisement
	 * @return view
	 * @author
	 * @created_at 14 June 2022
	 */
	const addNoteAction = async (noteData) => {
		let param = {
			note: noteData.note,
			attachment: noteData.attachment,
			claim_number: claim_number,
			beneficary_id: beneficiary_id,
			beneficary_name: beneficiary_id,
			claim_id: claim_id,
			file_type: noteData.file_type,
			document_name: noteData.document_name,
			client_code: attatchmentData.client_code,
			claimant_last_name: attatchmentData.last_name,
			beneficiary_first_name: attatchmentData.beneficiary_first_name

		}
		LoaderTrue();
		const isNote = await addNote(param);
		if (isNote.status === 1) {
			LoaderFalse();
			if (noteData.note) {
				setshowAddNotesModal(!showAddNotesModal);
			}
			if (noteData.attachment) {
				setshowUploadAttachmentModal(!showUploadAttachmentModal);
			}
			showToastMessage(isNote.message);

		} else {
			LoaderFalse();
			if (noteData.note) {
				setshowAddNotesModal(!showAddNotesModal);
			}
			if (noteData.attachment) {
				setshowUploadAttachmentModal(!showUploadAttachmentModal);
			}
			showToastMessage(isNote.message);

		}
	}



	const [showUpdateBeneficiaryModal, setShowUpdateBeneficiaryModal] = useState(false);

	/** Redux actions and state */

	const updateBeneficiary = (params) => dispatch(UpdateBeneficiaryAction.updateBeneficiary(params));
	/** Redux actions and state */
	// const { claim_status_loading: claimStatusInProgress, claim_status_errors: claimStatusErrors } = useSelector((state) => state.ClaimStatusReducer);
	const getClaimDetails = (params) => dispatch(ClaimStatusAction.getClaimDetails(params));
	const showToastMessage = (params) => dispatch(GlobalAction.showToastMessage(params));


	/**
	 * function to open/close status Update modal
	 * @return view
	 * @author
	 * @created_at 14 June 2022
	 */
	const openUpdateBeneficiaryModal = () => setShowUpdateBeneficiaryModal(true);
	const handleClose = () => setShowUpdateBeneficiaryModal(false);

	/**
	 * function to open/close status Update modal
	 * @return view
	 * @author
	 * @created_at 14 June 2022
	 */
	const LoaderTrue = () => setLoader(true);
	const LoaderFalse = () => setLoader(false);


	/**
	 * function to update Advertisement
	 * @return view
	 * @author
	 * @created_at 14 June 2022
	 */

	const updateBeneficiaryAction = async (updateData) => {
		handleClose();
		var { claim_data, beneficiary } = {};
		let param = {
			claim_number: claim_number,
			beneficary_id: beneficiary_id,
			email: updateData.email,
			phone: updateData.phone,
			address: {
				address: updateData.address,
				address1: updateData.address1,
				city: updateData.city,
				state: updateData.state,
				country_code: updateData.country_code,
				zip_code: updateData.zip_code
			}
		}
		LoaderTrue();
		const isUpdate = await updateBeneficiary(param);
		if (isUpdate.status === 1) {
			LoaderFalse();
			claim_data = isUpdate.data;
			isUpdate.data.claim.Beneficiaries.some(element => {
				if (beneficiary_id === element.id) {
					beneficiary = element;
					return true;
				}

			});
			claimData(claim_data, beneficiary);
			showToastMessage(isUpdate.message);
		}
	}

	useEffect(() => {
		if (claim_number && cookieServices.get('accessToken')) {
			getClaimData();
		} else {
			setTimeout(() => {
				// logs out user
				AutologOut();
			}, 100);
		}
	}, [claim_number])

	function AutologOut() {
		localStorage.removeItem("bot_chat_messages");
		cookieServices.remove('accessToken');
		navigate(`${preAuthRoutes('claim_info').path}`);
	}
	// claimData();

	/**
	 * function to dispatch sign in
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	async function getClaimData() {
		LoaderTrue();
		var { claim_data, beneficiary } = {};
		let param = {
			claim_number: claim_number
		}
		const isClaimStatus = await getClaimDetails(param);
		if (isClaimStatus.status === 1) {
			LoaderFalse();
			claim_data = isClaimStatus.data;
			isClaimStatus.data.claim.Beneficiaries.some(element => {
				if (beneficiary_id === element.id) {
					beneficiary = element;
					return true;
				}
			});
			claimData(claim_data, beneficiary);
			setAttatchmentData({client_code: claim_data.claim.clientCode, last_name: claim_data.claim.claimantInfo.lastName, beneficiary_first_name: isClaimStatus.data.claim.Beneficiaries.length > 1 ? beneficiary.firstName : ''});
			
		}
	}

	function claimData(claim_data, beneficiary) {
		let leftBenefits = [], rightBenefits = [];
		for (let i in beneficiary.benefits) {
			const item = beneficiary.benefits[i]
			if (i % 2 === 0) {
				leftBenefits.push(
					{ name: "Benefit " + (parseInt(i) + 1), value: "" },
					{ name: "Benefit Plan ", value: (item.benefitPlan !== null) ? " " + item.benefitPlan : "" },
					{ name: "Benefit Status", value: (item.status !== null) ? " " + item.status : "" },
					{ name: "Amount to Beneficiary", value: (item.amtToBeneficiary !== null) ? item.amtToBeneficiary : "" },
					{ name: "Date Complete", value: (item.completedDate !== null) ? " " + item.completedDate : "" }

				)
			} else {
				rightBenefits.push(
					{ name: "Benefit " + (parseInt(i) + 1), value: "" },
					{ name: "Benefit Plan ", value: (item.benefitPlan !== null) ? " " + item.benefitPlan : "" },
					{ name: "Benefit Status", value: (item.status !== null) ? " " + item.status : "" },
					{ name: "Amount to Beneficiary", value: (item.amtToBeneficiary !== null) ? item.amtToBeneficiary : "" },
					{ name: "Date Complete", value: (item.completedDate !== null) ? " " + item.completedDate : "" }
				)
			}

		}



		const icon = <IconButton aria-label="edit" size="large" color={'primary'} onClick={() => openUpdateBeneficiaryModal()} ><EditIcon /></IconButton>;

		setBeneficiaryData({
			email: ((beneficiary.email !== null) ? beneficiary.email : ""),
			phone: ((beneficiary.phoneNumber !== null) ? beneficiary.phoneNumber.replace(/[()\s-]/g, '') : ""),
			address: ((beneficiary.address !== null) ? beneficiary.address : ""),
			address1: ((beneficiary.address1 !== null) ? beneficiary.address1 : ""),
			city: ((beneficiary.city !== null) ? beneficiary.city : ""),
			state: ((beneficiary.state !== null) ? beneficiary.state : ""),
			country_code: 'US',
			zip_code: ((beneficiary.zipCode !== null) ? beneficiary.zipCode : "")
		});

		setProgressBarData([
			{ id: 1, title: 'Date Claim Received', date: (claim_data.claim.claimInfo.dateClaimReceived !== null) ? claim_data.claim.claimInfo.dateClaimReceived : '', status: (claim_data.claim.claimInfo.dateClaimReceived !== null) ? 'order-tracking completed' : 'order-tracking' },
			{ id: 2, title: 'Date Beneficiary Package Sent', date: (beneficiary.beneficiaryPackageSentDate !== null) ? beneficiary.beneficiaryPackageSentDate : '', status: (beneficiary.beneficiaryPackageSentDate !== null) ? 'order-tracking completed' : 'order-tracking' },
			// { id: 3, title: 'Date Documents Received', date: (claim_data.claim.claimInfo.dateDocumentsReceived !== null) ? claim_data.claim.claimInfo.dateDocumentsReceived : '', status: (claim_data.claim.claimInfo.dateDocumentsReceived !== null) ? 'order-tracking completed' : 'order-tracking' },
			{ id: 4, title: 'Date Claim Sent To Carrier', date: (beneficiary.claimSentToCarrier !== null) ? beneficiary.claimSentToCarrier : '', status: (beneficiary.claimSentToCarrier !== null) ? 'order-tracking completed' : 'order-tracking' },
			{ id: 5, title: 'Completed Date', date: (beneficiary.completedDate !== null) ? beneficiary.completedDate : '', status: (beneficiary.completedDate !== null) ? 'order-tracking completed' : 'order-tracking' }
		]);

		setData([
			{
				title: "Insured Information",
				sub_title: "",
				leftContent: {
					title: "",
					data: [
						{ name: "Insured Name", value: " " + claim_data.claim.claimantInfo.firstName + " " + claim_data.claim.claimantInfo.lastName },
						{ name: "Claim Type", value: (claim_data.claim.category !== null) ? " " + claim_data.claim.category : "" },
						{ name: "Relationship to Employee", value: (claim_data.claim.relationshipToEmployee !== null) ? " " + claim_data.claim.relationshipToEmployee : "" },
						{ name: "Counselor Assigned", value: (claim_data.claim.sosCounselor !== null) ? " " + claim_data.claim.sosCounselor : "" },
					],
				},
				rightContent: {
					title: "",
					data: [
						{ name: 'Life / AD&D Claim Number', value: ((claim_data.claim.claimInfo.lifeClaimNumber !== null) ? claim_data.claim.claimInfo.lifeClaimNumber.toUpperCase() : " ") + ((claim_data.claim.claimInfo.adAndDClaimNumber !== null) ? " / " + claim_data.claim.claimInfo.adAndDClaimNumber : " ") },
						{ name: "Date Claim Received", value: (claim_data.claim.claimInfo.dateClaimReceived !== null) ? claim_data.claim.claimInfo.dateClaimReceived : "" },
						{ name: "Date Claim Sent to Carrier", value: (beneficiary.claimSentToCarrier !== null) ? beneficiary.claimSentToCarrier : "" },
					],
				},
			},
			{
				title: "Beneficiary Information",
				sub_title: "",
				icon: icon,
				leftContent: {
					title: "",
					data: [
						{ name: "Beneficiary Name", value: " " + beneficiary.firstName + " " + beneficiary.lastName },
						{ name: "Address", value: ((beneficiary.address !== null) ? beneficiary.address : "") + ((beneficiary.address1 !== null) ? " ," + beneficiary.address1 : "") },
						{ name: "City/State/ZIP", value: ((beneficiary.city !== null) ? beneficiary.city : "") + ((beneficiary.state !== null) ? " / " + beneficiary.state : "") + ((beneficiary.zipCode !== null) ? " / " + beneficiary.zipCode : "") },

					],
				},
				rightContent: {
					title: "",
					data: [
						{ name: 'Phone Number', value: ((beneficiary.phoneNumber !== null) ? beneficiary.phoneNumber : "") },
						{ name: "Email", value: (beneficiary.email !== null) ? beneficiary.email : "" },
						{ name: "Beneficiary Package Sent Date", value: (beneficiary.beneficiaryPackageSentDate !== null) ? beneficiary.beneficiaryPackageSentDate : "" }
					],
				},
			},


			{
				title: "Benefit Information",
				sub_title: "",
				leftContent: {
					title: "",
					data: leftBenefits
				},
				rightContent: {
					title: "",
					data: rightBenefits
				},
			},


		]);


	}



	return (

		<AppLogout>
			<PageLoader loader={loader} />
			<Grid container justify="space-around" spacing={3}>
				<Grid item xs={12} sm={12} md={2} lg={2} xl={2} >
					<GeneralButton label='Add a note' fullWidth={`true`} onClick={() => openAddNoteModal()} />
				</Grid>
				<Grid item xs={12} sm={12} md={2} lg={2} xl={2} >
					<GeneralButton label='Upload document' fullWidth={`true`} onClick={() => openUploadAttchmentModal()} />

				</Grid>

				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<ProgressBar posts={progressBarData} ></ProgressBar>
				</Grid>
				{data.map((row, index) => (
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>

						<Card>
							<CardHeader title={row.title} subheader={row.sub_title} action={row.icon} />
							<CardContent>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										{row.leftContent.title && (
											<Typography variant="h6" mb={1}>
												{row.leftContent.title}
											</Typography>
										)}
										<StyledTableContainer>
											<Table>
												<TableBody>
													{row.leftContent.data.map((row, index) => (
														<TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
															<TableCell component="th" scope="row">
																{row.name}
															</TableCell>
															<TableCell>{row.value}</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</StyledTableContainer>
									</Grid>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										{row.rightContent.title && (
											<Typography variant="h6" mb={1}>
												{row.rightContent.title}
											</Typography>
										)}
										<StyledTableContainer>
											<Table>
												<TableBody>
													{row.rightContent.data.map((row, index) => (
														<TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
															<TableCell component="th" scope="row">
																{row.name}
															</TableCell>
															<TableCell>{row.value}</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</StyledTableContainer>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				))}
				<AddNoteModal show={showAddNotesModal} closeModal={openAddNoteModal} addNote={addNoteAction} />
				<UploadAttchmentModal show={showUploadAttachmentModal} closeModal={openUploadAttchmentModal} addNote={addNoteAction} />

			</Grid>
			<UpdateBeneficiaryModal show={showUpdateBeneficiaryModal} closeModal={handleClose} updateBeneficiary={updateBeneficiaryAction} beneficiaryData={beneficiaryData} />
		</AppLogout>
	);
}

export { ClaimDetails };
