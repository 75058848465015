import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { cookieServices, APP_NAME, handleInputChange, validateForm } from '../../../_helpers';
import { PreAuth } from '../../../_layouts/pre-auth';
import { preAuthRoutes, postAuthRoutes } from '../../../routes';

import { FormInput } from '../../../_components/form';
import { GeneralButton, SubmitButton } from '../../../_components/controls';

import { Grid, Typography } from '@mui/material';
import { validate } from '.';

import { OtpAction } from '../../../redux/actions';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import { Box } from '@mui/system';

// Initialize form input values to null
const inputs = { otp: '' };

function Otp() {
	/** Initialize plugins and variables */
	const navigate = useNavigate();
	const dispatch = useDispatch();

	/** Redux actions and state */
	const { OTP_loading: otpInProgress, OTP_errors: otpErrors } = useSelector((state) => state.OtpReducer);
	const verifyOtp = (params) => dispatch(OtpAction.verifyOtp(params));
	const sendOtp = (params) => dispatch(OtpAction.sendOtp(params));

	/** claim_number ssn page */
	const location = useLocation();
	const { beneficiary, claim_number, claim_id, otp_mobile, otp_email, mobile_number } = location.state ?? {};

	/** Initialize and declare state */
	const [data, setData] = useState({ ...inputs });
	const [errors, setErrors] = useState({ ...inputs });
	const [action, setAction] = useState({ isSubmitted: false });
	// const [timer, setTimer] = useState(10); // Initial timer value in seconds
	const [timer, setTimer] = useState(600);
	const [showResendLink, setShowResendLink] = useState(false);

	useEffect(() => {
		setErrors({ ...otpErrors });
	}, [otpErrors]);

	// Function to start the countdown
	const resendOtp = () => {
		dispatchResendOtp();
	};

	useEffect(() => {
		if (timer > 0) {
			const countdown = setInterval(() => {
				setTimer(timer - 1);
			}, 1000);

			return () => clearInterval(countdown);
		} else {
			setShowResendLink(true); // Show the resend link when the timer reaches 0
		}
	}, [timer]);

	// Format the timer as "00:00"
	const minutes = Math.floor(timer / 60);
	const seconds = timer % 60;
	const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

	/**
	 * function to handle input changes and alter the value
	 * @param object e input object with name and value
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	function handleChange(e) {
		const { name, value } = handleInputChange(e);
		setErrors({ ...errors, ...validate(name, value, data) });
		data[name] = value;
		setData({ ...data });
	}

	/**
	 * function to handle submit
	 * @param object e form object
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	function handleSubmit(e) {
		e.preventDefault();
		setAction({ isSubmitted: true });
		const formErrors = {};
		for (const [name, value] of Object.entries(data)) {
			const inputError = validate(name, value);
			formErrors[name] = inputError[name];
		}
		let valid = validateForm(formErrors);
		if (!valid) {
			setErrors(formErrors);
			setAction({ isSubmitted: true });
			return;
		}
		dispatchOtpAction();
	}

	/**
	 * function to dispatch sign in
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	async function dispatchOtpAction() {
		let param = {
			claim_number: claim_number,
			last_of_ssn: beneficiary.ssn,
			dob: beneficiary.dateOfBirth,
			name: beneficiary.firstName + " " + beneficiary.lastName,
			email: beneficiary.email,
			mobile_number: beneficiary.phoneNumber,
			otp: data.otp
		};
		const isOtp = await verifyOtp(param);
		if (isOtp.status === 1) {
			let date = new Date();
			date.setTime(date.getTime() + 30 * 60 * 24 * 60 * 1000);
			const options = { path: '/', expires: date };
			cookieServices.set('accessToken', 'test', options);
			navigate(`${postAuthRoutes('claim_details').path}`, { state: { claim_number: claim_number, claim_id: claim_id, beneficiary_id: beneficiary.id } });
		} else {
			let formErrors = [];
			formErrors['otp'] = isOtp.errors.otp;
			setErrors(formErrors);
		}
	}

	async function dispatchResendOtp() {
		let param = {
			claim_number: claim_number,
			last_of_ssn: beneficiary.ssn,
			dob: beneficiary.dateOfBirth,
			name: beneficiary.firstName + " " + beneficiary.lastName,
			email: beneficiary.email,
			mobile_number: mobile_number,
			otp_email: otp_email,
			otp_mobile: otp_mobile
		};
		const isOtp = await sendOtp(param);

		if (isOtp.status === 1) {
			setTimer(600); // Reset the timer
			setShowResendLink(false); // Hide the resend link
		}
	}

	const routeChange = () => {
		navigate(`${preAuthRoutes('authenticate').path}`, { state: { claim_number: claim_number } });
	};

	const maskEmail = (email) => {
		const atIndex = email.indexOf('@');
		const prefix = email.slice(0, 3); // Assuming the prefix length is 3 characters
		const maskedPart = email.slice(prefix.length, atIndex);
		const maskedEmail = `${prefix}${'x'.repeat(maskedPart.length)}@${email.slice(atIndex + 1)}`;
		return maskedEmail;
	};

	const maskMobileNumber = (mobileNumber) => {
		let maskPart = 'x'.repeat(6);
		let unmaskedPart =  mobileNumber.substr(6 - mobileNumber.length);
		var maskedMobileNumber = maskPart + unmaskedPart;
		return maskedMobileNumber;
	};

	return (
		<PreAuth>
			<Helmet>
				<title>{`${APP_NAME} | ${preAuthRoutes('claim_info').name}`}</title>
			</Helmet>
			<form onSubmit={handleSubmit} noValidate>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography variant='h5' align='center'>{`Enter your authorization code to verify your identity`}</Typography>
					</Grid>
					{(timer > 0) && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography variant='body2' align='left'>{`An authorization code was sent to your ${otp_email ? `email ` : ``}${otp_email ? maskEmail(beneficiary.email) : ''}${otp_email && otp_mobile ? ` and ` : ``}${otp_mobile ? `phone ` : ``}${otp_mobile ? maskMobileNumber(mobile_number) : ''}. This code will expire in 10 minutes after you request it.`}</Typography>
					</Grid>}

					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<FormInput
							tabIndex={1}
							label='Authorization code'
							name='otp'
							value={data.otp}
							error={action.isSubmitted && errors.otp ? errors.otp : ''}
							onChange={handleChange}
							maxLength={6}
							autoFocus={true}
						/>
						<Box textAlign="right">
							{showResendLink ? (
								<GeneralButton label={`Request another authentication code`} onClick={resendOtp} tabIndex={5} size={`small`} variant={`text`} />
							) : (
								<Typography variant="body1">
									Request another authentication code in: {formattedTime}
								</Typography>
							)}
						</Box>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' alignItems='center' justifyContent='space-between'>
						<SubmitButton label={`Next`} loading={otpInProgress} tabIndex={3} fullWidth endIcon={<ArrowRightAltIcon />} />
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' alignItems='center' justifyContent='space-between'>
						<GeneralButton label={`Back`} onClick={routeChange} tabIndex={4} fullWidth endIcon={<WestIcon />} />
					</Grid>
				</Grid>
			</form>
		</PreAuth>
	);
}

export { Otp };
