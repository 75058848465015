import { createChatBotMessage } from "react-chatbot-kit";

const botName = "Bot";

const config = {
    // Defines the chatbot name
    botName: botName,
    // Defines an array of initial messages that will be displayed on first render
    initialMessages: [
        createChatBotMessage(`Hello! This is an automated messaging assistant service. How can I assist you?`),
    ],

    // Defines an object of custom styles if you want to override styles
    customStyles: {
        // Overrides the chatbot message styles
        botMessageBox: {
            backgroundColor: "#3483d2",
        },
        // Overrides the chat button styles
        chatButton: {
            backgroundColor: "#91BAA4",
        },
    },

};

export default config;