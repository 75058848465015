import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { FormHelperText } from '@mui/material';

function FormDynamicDashInput({dashPosition, maxLength, value, onChange, name, label, info, error}) {

	const [defaultVal, setDefaultVal] = useState((value && value!== '') ? value : '');

	const handleChange = (e) => {
		const inputValue = e.target.value;
		const dashedValue = dashInput(inputValue);
		setDefaultVal(dashedValue);
		onChange(e);
	};

	const dashInput = (input) => {
		let dashedInput = input.replace(/-/g, '').split('').map((val, index) => {
		if (dashPosition.includes(index + 1) && val !== '-') {
			return val.toUpperCase() + '-';
		}
		return val.toUpperCase();
		}).join('');

		if (dashedInput.endsWith('-')) {
			dashedInput = dashedInput.slice(0, -1);
		}
		return dashedInput;
	};

  return (
		<div className="DashInput">
			<TextField 
				name={name}
				label={label}
				variant="outlined" 
				value={defaultVal} 
				onChange={handleChange} 
				inputProps={{ maxLength: maxLength }}
				error={error ? true : false}
				fullWidth
			/>
			{(info) && <FormHelperText id={`helper_${name}`} >{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error>{error}</FormHelperText>}
		</div>
  );
}

export default FormDynamicDashInput;
