import React from 'react'
import { StyledCardContent } from '.'

function CardContent({ ...props }) {
    return (
        <StyledCardContent  {...props}>{props.children}</StyledCardContent>
    )
}

export { CardContent }
