module.exports = {
	/**
	 * function to validate the registration form inputs
	 * @param {string} name Input name
	 * @param {string} value Input value
	 * @param {object} [data={}] All input values stored in the state
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	validate: (name, value, data = {}) => {
		//eslint-disable-next-line
		const errors = {};
		switch (name) {
			case 'last_of_ssn': {
				errors.last_of_ssn = value.length < 4 ? 'Enter the last 4 digits of your SSN' : '';
				break;
			}
			case 'dob': {
				errors.dob = '';
				if (value === '') errors.dob = 'Select your date of birth';
				break;
			}
			case 'otp': {
				errors.otp = value.length < 6 ? 'Enter 6 digit authorization code' : '';
				break;
			}
			case 'otp_mobile': {
				errors.otp_mobile = '';
				errors.otp_email = '';

				if ((!value && !data.otp_email) || (value === false && data.otp_email === false)) errors.otp_mobile = 'Select phone or email for authorization code';
				break;
			}
			case 'otp_email': {
				errors.otp_email = '';
				errors.otp_mobile = '';

				if ((!value && !data.otp_mobile) || (value === false && data.otp_mobile === false)) errors.otp_email = 'Select phone or email for authorization code';
				break;
			}
			default: {
				errors[name] = '';
				break;
			}
		}
		return errors;
	},
};
