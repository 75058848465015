import { StyledCardHeader } from "."
import PropTypes from 'prop-types'

function CardHeader({ title, action, ...props }) {
    return (
        <StyledCardHeader title={title} titleTypographyProps={{ variant: 'h5', component: 'h5' }} action={action} {...props} />
    )
}

CardHeader.defaultProps = {
    action: null
}


CardHeader.propTypes = {
    title: PropTypes.string,
    action: PropTypes.element
}

export { CardHeader }
