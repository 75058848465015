import {
	OTP_REQUEST, OTP_SUCCESS, OTP_FAILURE
} from "../actions";


const initialState = {
	OTP_loading: false, otp: {}, OTP_errors: {}
}


export function OtpReducer(state = initialState, action) {
	switch (action.type) {

		

		/** begin::otp status redux */
		case OTP_REQUEST: {
			return {
				OTP_loading: true,
				otp: {},
				OTP_errors: {}
			};
		}
		case OTP_SUCCESS: {
			return {
				OTP_loading: false,
				otp: action.data,
				OTP_errors: {}
			};
		}
		case OTP_FAILURE: {
			return {
				OTP_loading: false,
				otp: {},
				OTP_errors: action.errors
			};
		}
		/** end::otp status redux */

		default:
			return state;
	}
}
