import React from 'react';
import { Card, styled } from '@mui/material';

const StyledCard = styled(({ ...props }) => <Card {...props} />)`
	padding: ${(props) => props.theme.spacing(4)};
	background: ${(props) => props.theme.palette.primaryLight.main};
	&:last-child {
		padding-bottom: ${(props) => props.theme.spacing(4)};
	}
`;

export { StyledCard };
