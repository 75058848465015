import { apiServices } from '../../_helpers';


export const AddNoteServices = {

    
    /**
     * Service for claim status check 
     *
     * @parm json params checkClaimStatus parameters of {claim_number}
     * @author Drishya 
     */
    addNote: (params) => {

        return apiServices.post('/add-note', params, { headers: { 'Content-Type': 'multipart/form-data' }})
        .then((response) => {
             return response; 
            })
            
    }    

};
