import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const LogoBox = styled(({ ...props }) => (
	<svg viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg' {...props} >
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M26.9506 17.467H38.1905C39.6452 17.467 40.8244 16.2794 40.8244 14.8145V3.11931C40.8244 1.65436 39.6452 0.466797 38.1905 0.466797H25.1411V0.466917H2.63383C1.17921 0.466917 0 1.64612 0 3.10075V37.4602C0 38.9149 1.17921 40.0941 2.63383 40.0941H29.2116L29.2116 23.2136H20.7115C19.1246 23.2136 17.8382 21.9272 17.8382 20.3404C17.8382 18.7535 19.1246 17.4671 20.7115 17.4671H26.9506C26.9506 17.467 26.9506 17.467 26.9506 17.467ZM38.1905 40.0941H35.5567L35.5567 32.6713L35.5567 31.6538L35.5567 23.2135H38.1905C39.6451 23.2135 40.8243 24.3927 40.8243 25.8473L40.8244 31.6538L40.8243 32.6713L40.8244 37.4602C40.8244 38.9149 39.6451 40.0941 38.1905 40.0941Z'
			fill='#91BAA4'
			className='light'
		/>
	</svg>
))`
	& .light {
		fill: ${props => (props.fill) ? (props.fill) : (props.theme.palette.mode === 'dark') ? (props.theme.palette.primary.main) : (props.theme.palette.primary.main)};
	}
` ;


const ShortLogo = ({ alt, width, className, style, ...props }) => {
	return (
		<LogoBox width={width} className={className} style={style} {...props} />
	)
}

ShortLogo.defaultProps = {
	alt: 'logo',
	width: '50',
	className: '',
};

ShortLogo.propTypes = {
	props: PropTypes.object,
	alt: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
	className: PropTypes.string,
};

export { ShortLogo };
