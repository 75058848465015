import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { APP_NAME, handleInputChange, validateForm } from '../../../_helpers';
import { PreAuth } from '../../../_layouts/pre-auth';
import { preAuthRoutes } from '../../../routes';

import { FormInput } from '../../../_components/form';
import { SubmitButton } from '../../../_components/controls';

import { Grid, Typography } from '@mui/material';
import { validate } from '.';

import { ClaimStatusAction } from '../../../redux/actions';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from 'react-router';
import { cookieServices } from '../../../_helpers';
import FormDynamicDashInput from '../../../_components/form/form-dynamic-dash-input/FormDynamicDashInput';

// Initialize form input values to null
const inputs = { claim_number: '' };

function ClaimInfo() {
	/** Initialize plugins and variables */
	const navigate = useNavigate();
	const dispatch = useDispatch();

	/** Redux actions and state */
	const { claim_status_loading: claimStatusInProgress, claim_status_errors: claimStatusErrors } = useSelector((state) => state.ClaimStatusReducer);
    const checkClaimStatus = (params) => dispatch(ClaimStatusAction.checkClaimStatus(params));

	/** Initialize and declare state */
	const [data, setData] = useState({ ...inputs });
	const [errors, setErrors] = useState({ ...inputs });
	const [action, setAction] = useState({ isSubmitted: false });

	useEffect(() => {
        setErrors({ ...claimStatusErrors });
		cookieServices.remove('accessToken');

    }, [claimStatusErrors])

	/**
	 * function to handle input changes and alter the value
	 * @param object e input object with name and value
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	function handleChange(e) {
		const { name, value } = handleInputChange(e);
		setErrors({ ...errors, ...validate(name, value, data) });
		data[name] = value;
		setData({ ...data });
	}

	/**
	 * function to handle submit
	 * @param object e form object
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	function handleSubmit(e) {
		e.preventDefault();
		setAction({ isSubmitted: true });
		const formErrors = {};
		for (const [name, value] of Object.entries(data)) {
			const inputError = validate(name, value);
			formErrors[name] = inputError[name];
		}
		let valid = validateForm(formErrors);
		if (!valid) {
			setErrors(formErrors);
			setAction({ isSubmitted: true });
			return;
		}
		dispatchClaimStatusAction();
	}

	/**
     * function to dispatch sign in
     * @author Akshay N
     * @created_at 30 May 2022
     */
    async function dispatchClaimStatusAction() {
        const isClaimStatus = await checkClaimStatus(data);
        if (isClaimStatus.status === 1) {
			navigate(`${preAuthRoutes('authenticate').path}`, { state: { claim_number: data.claim_number } });
        }
    }

	return (
		<PreAuth>
			<Helmet>
				<title>{`${APP_NAME} | ${preAuthRoutes('claim_info').name}`}</title>
			</Helmet>
			<form onSubmit={handleSubmit} noValidate>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography variant='h5' align='center'>{`${preAuthRoutes('claim_info').name}`}</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<FormDynamicDashInput 
							tabIndex={1}
							label='Claim Number'
							name='claim_number'
							value={data.claim_number}
							dashPosition = {[1,4,8]} 
							maxLength={16}
							error={action.isSubmitted && errors.claim_number ? errors.claim_number : ''}
							autoFocus={true}
							onChange={handleChange}
						/>
				
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' alignItems='center' justifyContent='space-between'>
						<SubmitButton label={`Next`} loading={claimStatusInProgress} tabIndex={3} fullWidth endIcon={<ArrowRightAltIcon />} />
					</Grid>
				</Grid>
			</form>
		</PreAuth>
	);
}

export { ClaimInfo };
