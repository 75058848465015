import React from 'react';
import { Helmet } from 'react-helmet-async';

import { APP_NAME } from '../../../_helpers';
import { PreAuth } from '../../../_layouts/pre-auth';
import { preAuthRoutes } from '../../../routes';
import { GeneralButton } from '../../../_components/controls';

import { Grid, Typography } from '@mui/material';

import { cookieServices } from '../../../_helpers';
import { useNavigate } from 'react-router';

import WestIcon from '@mui/icons-material/West';

function AutoLogout() {
	const navigate = useNavigate();
	const routeChange = () => {
		localStorage.removeItem("bot_chat_messages");
		cookieServices.remove('accessToken');
		navigate(`${preAuthRoutes('claim_info').path}`);
	};

	return (
		<PreAuth>
			<Helmet>
				<title>{`${APP_NAME} | ${preAuthRoutes('auto_logout').name}`}</title>
			</Helmet>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Typography variant='h5' align='center'>For your data security, you have been logged out due to an extended period of inactivity</Typography>
				</Grid>

				<Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' alignItems='center' justifyContent='space-between'>
					<GeneralButton label={`Back`} onClick={routeChange} tabIndex={3} fullWidth endIcon={<WestIcon />} />
				</Grid>
			</Grid>
		</PreAuth>
	);
}

export { AutoLogout };
