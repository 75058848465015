import { apiServices } from '../../_helpers';


export const ChatBotServices = {

    /**
     * Service for chatbot
     *
     * @parm json params
     * @author Drishya 
     */
    chat: (params) => {

        return apiServices.post('/chat', params,{ timeout:120000 })
        .then((response) => {
             return response; 
            })
            
    }
};
