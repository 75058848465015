import { Card,  CardHeader, Grid } from '@mui/material';
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalFooterCloseBtn, ModalFooterSaveBtn } from '..'
import { validate } from './validate';
import { handleInputChange,validateForm } from '../../../_helpers';
import { FormBrowseFile, FormInput, FormSelect } from '../../../_components/form';

const fileTypeSelect = [
    {label: 'Death Certificate', value: 'DC'},
    {label: 'Beneficiary/Claimant Statement', value: 'BS'},
    {label: 'Preferential Beneficiary Statement/Affidavit', value: 'PBS'},
    {label: 'Driver’s License', value: 'DL'},
    {label: 'Birth Certificate', value: 'BC'},
    {label: 'Marriage License', value: 'ML'},
    {label: 'W-9', value: 'W-9'},
    {label: 'Estate Document', value: 'Estate Document'},
    {label: 'Guardianship Document', value: 'Guardianship Document'},
    {label: 'Police/Accident Report', value: 'Police/Accident Report'},
    {label: 'Other', value: 'Other'},
]

function UploadAttchmentModal({ show, showLoader, closeModal, addNote }) {

    const [noteData, setNoteData] = useState({ attachment : '', file_type: '', document_name: '' });
    const [action,setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [errors, setErrors] = useState({ ...noteData });

    /**
     * function to handle input changes and modify the value
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, noteData)) });
        noteData[name] = value;
        setNoteData({ ...noteData });
    }

    const onClose = () => {
        setNoteData({ attachment : '', file_type: '', document_name: '' });
        closeModal(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ isSubmitted: true });
		const formErrors = {};
		for (const [name, value] of Object.entries(noteData)) {
			const inputError = validate(name, value, noteData);
			formErrors[name] = inputError[name];
		}
		let valid = validateForm(formErrors);
		if (!valid) {
			setErrors(formErrors);
			setAction({ isSubmitted: true });
			return;
		}
        addNote(noteData);
        setNoteData({ attachment :'', file_type: '', document_name: '' });
    }

    return (
        <Modal isDialogOpen={show} onClose={onClose} loader={showLoader} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
            <Card style={{ boxShadow: `none`, padding: 30 }}>
            <CardHeader title='Upload document' />
                {/* <CardMedia component="img" image={questinMarkSvg} style={{ position: "absolute", padding: 0, bottom: 0, right: 0, top: 0, width: "60%", opacity: '0.1' }} /> */}
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <ModalBody style={{ padding: 0 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormBrowseFile
                                    tabIndex={1}
                                    name="attachment"
                                    error={(action.isSubmitted && (errors.attachment)) ? (errors.attachment) : ''}
                                    value={noteData.attachment}
                                    placeholder='Upload'
                                    onChange={handleChange}
                                    acceptType=".pdf,.doc,.docx,.csv,.jpeg,.jpg,.png,xlsx"
                                    info={`Allowed Format: PDF, DOC, DOCX, CSV, JPEG, JPG, PNG, XLSX | Allowed Maximum Size: 10 MB`}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormSelect
                                    tabIndex={2}
                                    name="file_type"
                                    error={(action.isSubmitted && (errors.file_type)) ? (errors.file_type) : ''}
                                    value={noteData.file_type}
                                    label='File Type'
                                    placeholder='File Type'
                                    data={fileTypeSelect}
                                    onChange={handleChange}
                                />
                            </Grid>
                            {noteData.file_type === 'Other' && 
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormInput
                                        tabIndex={3}
                                        label='Document Name'
                                        name='document_name'
                                        value={noteData.document_name}
                                        error={action.isSubmitted && errors.document_name ? errors.document_name : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </ModalBody>
                    <ModalFooter style={{ padding: 0 }}>
                        <ModalFooterSaveBtn tabIndex={4} text={`Upload`} isProcessing={action.isProcessing} />
                        <ModalFooterCloseBtn tabIndex={5} onClose={onClose} />
                    </ModalFooter>
                </form>
            </Card>
        </Modal>
    )
}

export { UploadAttchmentModal }
