import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChatBotAction } from '../../../redux/actions';
import { useLocation } from 'react-router';

const ChatbotActionProvider = ({ createChatBotMessage, setState, children }) => {

    const dispatch = useDispatch();
    const location = useLocation();

    const { beneficiary_id } = location.state ?? {};

    /** Redux actions and state */
    const { chat_bot_history } = useSelector((state) => state.ChatBotReducer);
    const { claim } = useSelector((state) => state.ClaimStatusReducer);
    const [beneficiary, setBeneficiary] = useState({});

    const chat = (params) => dispatch(ChatBotAction.chat(params));

    useEffect(() => {

        let beneficiaryData = {};

        if (claim && claim.claim) {
            claim.claim.Beneficiaries.some(element => {
                if (beneficiary_id === element.id) {
                    beneficiaryData = element;
                    return true;
                }
            });
        }

        setBeneficiary(beneficiaryData);

    }, [claim, beneficiary_id])


    const handleResponse = (message) => {

        const chatLoader = <div className="chatbot-loader-container">
            <svg
                id="dots"
                width="50px"
                height="21px"
                viewBox="0 0 132 58"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g stroke="none" fill="none">
                    <g id="chatbot-loader" fill="#fff">
                        <circle id="chatbot-loader-dot1" cx="25" cy="30" r="13"></circle>
                        <circle id="chatbot-loader-dot2" cx="65" cy="30" r="13"></circle>
                        <circle id="chatbot-loader-dot3" cx="105" cy="30" r="13"></circle>
                    </g>
                </g>
            </svg>
        </div>;

        let botMessage = createChatBotMessage(chatLoader);

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));

        chat({ message, history: chat_bot_history, claim: claim.claim, beneficiary: beneficiary }).then(result => {
            if (result.status) {
                let botMessage = createChatBotMessage(result.message.text);

                setState((prev) => {
                    const prevMessagesWithoutLast = prev.messages.slice(0, -1)

                    localStorage.setItem("bot_chat_messages", JSON.stringify({
                        ...prev,
                        messages: [...prevMessagesWithoutLast, botMessage],
                    }));

                    return {
                        ...prev,
                        messages: [...prevMessagesWithoutLast, botMessage],
                    }
                });

            } else {
                setState((prev) => {
                    const prevMessagesWithoutLast = prev.messages.slice(0, -1)

                    localStorage.setItem("bot_chat_messages", JSON.stringify({
                        ...prev,
                        messages: [...prevMessagesWithoutLast],
                    }));

                    return {
                        ...prev,
                        messages: [...prevMessagesWithoutLast],
                    }
                });
            }

        });

    };

    // Put the handleResponse function in the actions object to pass to the MessageParser
    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        handleResponse,
                    },
                });
            })}
        </div>
    );
};

export default ChatbotActionProvider;