import { Box, Card,  CardContent,  CardHeader, CardMedia, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalFooterCloseBtn, ModalFooterSaveBtn } from '..'

function LogoutModal({ show, showLoader, closeModal, logout }) {

    const [action,setAction] = useState({ isProcessing: false, isSubmitted: false });

    const onClose = () => {
        closeModal(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        logout();

    }

    return (
        <Modal isDialogOpen={show} onClose={closeModal} loader={showLoader} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
        <Card style={{ boxShadow: `none` }}>
        <CardHeader title='Confirm Logout' />

            <form onSubmit={handleSubmit} noValidate className='ns-form' >
                <ModalBody style={{ padding: 0 }}>
                    <CardContent style={{paddingBottom: 0 }}>
                        <Typography variant='body1'>Are you sure you want to logout?</Typography>
                    </CardContent>
                </ModalBody>
                <ModalFooter>
                    <Box style={{ width: `100%` }}>
                        <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                            <ModalFooterSaveBtn tabIndex={3} text={`Yes`} isProcessing={action.isProcessing} />
                            <ModalFooterCloseBtn tabIndex={4} text={`Cancel`} onClose={onClose} />
                        </Stack>
                    </Box>
                </ModalFooter>
            </form>
            </Card>
    </Modal>
    )
}

export { LogoutModal }
