import { apiServices } from '../../_helpers';


export const UpdateBeneficiaryServices = {

    
    /**
     * Service for claim status check 
     *
     * @parm json params checkClaimStatus parameters of {claim_number}
     * @author Drishya 
     */
    updateBeneficiary: (params) => {

        return apiServices.patch('/update-beneficiary', params)
        .then((response) => {
             return response; 
            })
            
    }, 

};
