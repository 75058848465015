import React from 'react'
import { styled, CardHeader } from '@mui/material';

const StyledCardHeader = styled(({ ...props }) => (<CardHeader {...props} />))`
        padding: ${props => props.theme.spacing(1)};
        ${'' /* margin-bottom: 25px; */}
        padding-left:0;
        & .MuiTypography-root {
            font-weight: 600;
            position: relative;
            ${'' /* &:after {
                content: "";
                display: block;
                position: absolute;
                z-index: 1;
                width: 100px;
                height: 2px;
                background: ${props => props.theme.palette.primary.main}; 
            } */}
        }
`;

export { StyledCardHeader }