export const INITIAL_LOADER = 'INITIAL_LOADER';
export const PAGE_LOADER = 'PAGE_LOADER';
export const SET_DRAWER = 'SET_DRAWER';
export const SET_TOAST = 'SET_TOAST';
export const SET_THEME = 'SET_THEME';

export const GlobalAction = {

    /**
     * Action for showing initial loader
     * @param {Object} loader
     * @author Akshay 
     * @created_at 15 June 2022
     */
    initialLoader: (loader) => {
        return { type: INITIAL_LOADER, loader: loader }
    },

    /**
     * Action for showing page loader
     * @param {Object} loader 
     * @author Akshay 
     * @created_at 15 June 2022
     */
    pageLoader: (loader) => {
        return { type: PAGE_LOADER, loader: loader }
    },

    /**
     * Action for setting drawer
     * @param {Object} payload 
     * @author Akshay 
     * @created_at 15 June 2022
     */
    setDrawer: (payload) => {
        localStorage.setItem('is_drawer_open', payload);
        return { type: SET_DRAWER, isOpen: payload }
    },
    /**
     * Action for showing notification message
     * @param {Object} message
     * @author Akshay 
     * @created_at 15 June 2022
     */
    showToastMessage: (message) => {
        return { type: SET_TOAST, message: message }
    },
    setTheme: (mode) => {
        return { type: SET_THEME, mode: mode }
    }


};





