import { apiServices } from '../../_helpers';


export const ClaimStatusServices = {

    
    /**
     * Service for claim status check 
     *
     * @parm json params checkClaimStatus parameters of {claim_number}
     * @author Drishya 
     */
    checkClaimStatus: (params) => {

        return apiServices.post('/check-claim-status', params)
        .then((response) => {
             return response; 
            })
            // .then((response) => {
            //     let date = new Date();
            //     date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
            //     const options = { path: '/', expires: date };
            //     cookieServices.set('accessToken', response.data.token, options);
            //     return response;
            // })
    },

    /**
     * Service for claim status check 
     *
     * @parm json params checkClaimStatus parameters of {claim_number}
     * @author Drishya 
     */
    getClaimDetails: (params) => {

        return apiServices.post('/get-claim-details', params)
        .then((response) => {
             return response; 
            })
    },

    /**
     * Service for claim status check 
     *
     * @parm json params checkClaimStatus parameters of {claim_number}
     * @author Drishya 
     */
    checkSsnandDob: (params) => {

        return apiServices.post('/check-ssn-and-dob', params)
        .then((response) => {
             return response; 
            })
    },

    

};
