import {
	CLAIM_STATUS_REQUEST, CLAIM_STATUS_SUCCESS, CLAIM_STATUS_FAILURE
} from "../actions";


const initialState = {
	claim_status_loading: false, claim: {}, claim_status_errors: {}
}


export function ClaimStatusReducer(state = initialState, action) {
	switch (action.type) {

		

		/** begin::claim status redux */
		case CLAIM_STATUS_REQUEST: {
			return {
				claim_status_loading: true,
				claim: {},
				claim_status_errors: {}
			};
		}
		case CLAIM_STATUS_SUCCESS: {
			return {
				claim_status_loading: false,
				claim: action.data,
				claim_status_errors: {}
			};
		}
		case CLAIM_STATUS_FAILURE: {
			return {
				claim_status_loading: false,
				claim: {},
				claim_status_errors: action.errors
			};
		}
		/** end::claim status redux */

		default:
			return state;
	}
}
