import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { cookieServices } from '../_helpers';
import { PostAuth } from '../_layouts/post-auth';
import { preAuthRoutes } from './PreAuthRoutes';
import { useLocation } from 'react-router-dom';


export const PrivateRoute = ({ ...props }) => {
    
    const location = useLocation(); // React Hook
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to pre auth page
    return cookieServices.get('accessToken') && (location.pathname === '/claim-details')  ? <PostAuth><Outlet /></PostAuth> : <Navigate to={`${preAuthRoutes('claim_info').path}`} />
}
