import { AddNoteServices } from "../services";


export const NOTE_REQUEST = 'NOTE_REQUEST';
export const NOTE_SUCCESS = 'NOTE_SUCCESS';
export const NOTE_FAILURE = 'NOTE_FAILURE';


export const AddNoteAction = {
    /**
    * Action for sign in 
    * @param {Object} params - The params which are used for sending otp.
    * @param {string} params.claim_number - Claim_number of the user
    * @author Akshay 
    */
    addNote: (params) => {
        return dispatch => {
            dispatch(request(params));
            return AddNoteServices.addNote(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: NOTE_REQUEST, request: request } }
        function success(request, response) { return { type: NOTE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: NOTE_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    } 

};



