import { Checkbox, FormControlLabel } from "@mui/material";
import { FormControl, FormHelperText } from "@mui/material";
import { FormCheckboxStyle as useStyles } from '.';

import React from "react";

/**
 * Declare default value of input properties 
 */
const defaultProps = {
    type: 'text',
    isReadOnly: false,
    maxLength: 255,
    className: '',
};

const FormCheckbox = ({ name, label, className, id, value, onChange, isReadOnly, tabIndex, error, info, ...props }) => {

    const classes = useStyles();

    const handleChange = (e) => {
        onChange(e);
    }

    return (
        <FormControl fullWidth className={classes.formControl} error >
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        name={name}
                    />
                }
                label={label}
            />

            {(info) && <FormHelperText id={`helper_${name}`} className={classes.info}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error className={classes.info}>{error}</FormHelperText>}
        </FormControl>
    );
};

FormCheckbox.defaultProps = defaultProps;

export { FormCheckbox };
