import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormHelperText } from '@mui/material';
import moment from 'moment';
import { StyledFormDatePicker } from './StyledFormDatePicker';

/**
 * Declare default value of input properties
 */
const defaultProps = {
	className: '',
	isReadOnly: false,
	maxLength: 255,
	maskType: '',
};

function FormDatePicker({
	label,
	name,
	placeholder,
	className,
	onChange,
	tabIndex,
	id,
	error,
	isReadOnly,
	pattern,
	maxLength,
	maskType,
	minDate = null,
	value,
	info,
	views,
	inputFormat,
	disableFuture = false,
	readOnly=false,
	...props
}) {
	const [val, setVal] = React.useState(value && value !== '' ? moment(value, 'DD-MM-yyyy').format('MM-DD-YYYY') : null);

	const [open, setOpen] = React.useState(false);

	const handleChange = (e) => {
		setVal(e);
		onChange({ target: { type: `date_time`, name: name, value: moment(e).format('DD/MM/yyyy') } });
	};

	return (
		<StyledFormDatePicker fullWidth className={`${className}`}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					open={open}
					onOpen={() => setOpen(true)}
					onClose={() => setOpen(false)}
					views={views} //['year', 'month']
					value={val}
					onChange={handleChange}
					label={label}
					// onError={console.log}
					minDate={minDate}
					disableFuture={disableFuture}
					inputFormat={inputFormat ?? 'MM/dd/yyyy'}
					readOnly={isReadOnly}
					renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps,readOnly: true}} onClick={(e) => setOpen(true)}/>}
				/>
			</LocalizationProvider>
			{info && <FormHelperText id={`helper_${name}`}>{info}</FormHelperText>}
			{error && (
				<FormHelperText id={`error_${name}`} error>
					{error}
				</FormHelperText>
			)}
		</StyledFormDatePicker>
	);
}

FormDatePicker.defaultProps = defaultProps;

export { FormDatePicker };
