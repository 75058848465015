import { Box, Card, CardContent, CardHeader, CardMedia, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalFooterCloseBtn, ModalFooterSaveBtn } from '..';
import { FormPhoneInput } from '../../form';
import { handleInputChange, validateForm } from '../../../_helpers';
import { validate } from './validate';

function PhoneOtpModal({ show, showLoader, closeModal, logoutModalSubmit }) {

    const [otpModalData, setOtpModalData] = useState({ otp_mobile_number: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [errors, setErrors] = useState({ ...otpModalData });

    const onClose = () => {

        setOtpModalData({ otp_mobile_number: '' });
        setErrors({ otp_mobile_number: '' });
        closeModal(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(otpModalData)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        logoutModalSubmit(otpModalData.otp_mobile_number);

        onClose();

    };

    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, otpModalData)) });
        otpModalData[name] = value;

        setOtpModalData({ ...otpModalData });
    };

    return (
        <Modal isDialogOpen={show} onClose={onClose} loader={showLoader} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
            <Card style={{ boxShadow: `none` }}>
                <CardHeader />

                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <ModalBody style={{ padding: 0 }}>
                        <CardContent style={{ paddingBottom: 0 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant='body1'>Please enter your mobile number. We’ll send you an authorization code to confirm it’s you. Message and data rates may apply.</Typography>
                                    {/* <Typography variant='body1'>Please enter your cell phone to receive an authorization code. By entering in your cell phone number, you agree to receive SMS text messages from TenCode Benefits.
                                Usual SMS / data rates will apply for sending the authorization code to you.</Typography> */}
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormPhoneInput
                                        tabIndex={1}
                                        label='Phone Number'
                                        name='otp_mobile_number'
                                        value={otpModalData.otp_mobile_number ? otpModalData.otp_mobile_number : ''}
                                        error={action.isSubmitted && errors.otp_mobile_number ? errors.otp_mobile_number : ''}
                                        onChange={handleChange}
                                        maskType={`digit-only`}
                                        maxLength={13}
                                        allowDropdown = {false}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </ModalBody>
                    <ModalFooter>
                        <Box style={{ width: `100%` }}>
                            <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                                <ModalFooterSaveBtn tabIndex={2} text={`OK`} isProcessing={action.isProcessing} />
                                <ModalFooterCloseBtn tabIndex={3} text={`Cancel`} onClose={onClose} />
                            </Stack>
                        </Box>
                    </ModalFooter>
                </form>
            </Card>
        </Modal>
    );
}

export { PhoneOtpModal };
