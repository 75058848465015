import { ClaimStatusServices } from "../services";


export const CLAIM_STATUS_REQUEST = 'CLAIM_STATUS_REQUEST';
export const CLAIM_STATUS_SUCCESS = 'CLAIM_STATUS_SUCCESS';
export const CLAIM_STATUS_FAILURE = 'CLAIM_STATUS_FAILURE';


export const ClaimStatusAction = {
    /**
    * Action for sign in 
    * @param {Object} params - The params which are used for checkClaimStatus api.
    * @param {string} params.claim_number - Claim_number of the user
    * @author Akshay 
    */
    checkClaimStatus: (params) => {
        return dispatch => {
            dispatch(request(params));
            return ClaimStatusServices.checkClaimStatus(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CLAIM_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CLAIM_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CLAIM_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action for sign in 
    * @param {Object} params - The params which are used for checkClaimStatus api.
    * @param {string} params.claim_number - Claim_number of the user
    * @author Akshay 
    */
    getClaimDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return ClaimStatusServices.getClaimDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CLAIM_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CLAIM_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CLAIM_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action for sign in 
    * @param {Object} params - The params which are used for checkClaimStatus api.
    * @param {string} params.claim_number - Claim_number of the user
    * @author Akshay 
    */
    checkSsnandDob: (params) => {
        return dispatch => {
            dispatch(request(params));
            return ClaimStatusServices.checkSsnandDob(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CLAIM_STATUS_REQUEST, request: request } }
        function success(request, response) { return { type: CLAIM_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CLAIM_STATUS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    
};



