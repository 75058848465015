import { useEffect } from "react";
import { cookieServices } from '../../../_helpers';
import { preAuthRoutes } from '../../../routes';


const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const AppLogout = ({ children }) => {

  let timer;

  // this function sets the timer that logs out the user after 5 mins
  const handleLogoutTimer = () => {
    if (cookieServices.get('accessToken')) {
      timer = setTimeout(() => {
        // clears any pending timer.
        resetTimer();
        // Listener clean up. Removes the existing event listener from the window
        Object.values(events).forEach((item) => {
          window.removeEventListener(item, resetTimer);
        });
        // logs out user
        logoutAction();
      }, 900000); // 10000ms = 10secs. You can change the time.
    }
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {
    localStorage.removeItem("bot_chat_messages");
    cookieServices.remove('accessToken');
    window.location.href = `${preAuthRoutes('auto_logout').path}`;
  };

  return children;
};

export { AppLogout };