import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { APP_NAME, handleInputChange, validateForm } from '../../../_helpers';
import { PreAuth } from '../../../_layouts/pre-auth';
import { preAuthRoutes } from '../../../routes';

import { FormCheckbox, FormDatePicker, FormGroup, FormInput } from '../../../_components/form';
import { GeneralButton, SubmitButton } from '../../../_components/controls';

import { Grid, Typography } from '@mui/material';
import { validate } from '.';

import { ClaimStatusAction, OtpAction } from '../../../redux/actions';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { GlobalAction } from '../../../redux/actions';
import WestIcon from '@mui/icons-material/West';
import { PhoneOtpModal } from '../../../_components/modal/modals/PhoneOtpModal';

// Initialize form input values to null
const inputs = { claim_number: '', last_of_ssn: '', dob: '', otp_mobile: false, otp_email: false, mobile_number: '' };

function Authenticate() {
	/** Initialize plugins and variables */
	const navigate = useNavigate();
	const dispatch = useDispatch();

	/** claim_number ssn page */
	const location = useLocation();
	const { claim_number } = location.state ?? {};

	/** Redux actions and state */
	const { claim_status_loading: claimStatusInProgress, claim_status_errors: claimStatusErrors } = useSelector((state) => state.ClaimStatusReducer);
	const checkSsnandDob = (params) => dispatch(ClaimStatusAction.checkSsnandDob(params));
	/** Redux actions and state */
	const { otp_errors: otpErrors } = useSelector((state) => state.OtpReducer);
	const sendOtp = (params) => dispatch(OtpAction.sendOtp(params));
	const showToastMessage = (params) => dispatch(GlobalAction.showToastMessage(params));

	/** Initialize and declare state */
	const [data, setData] = useState({ ...inputs });
	const [errors, setErrors] = useState({ ...inputs });
	const [action, setAction] = useState({ isSubmitted: false });

	const [showLogoutModal, setshowLogoutModal] = useState(false);

	useEffect(() => {
		setErrors({ ...claimStatusErrors });

	}, [claimStatusErrors])

	useEffect(() => {
		setErrors({ ...otpErrors });

	}, [otpErrors])

	useEffect(() => {

		if (claim_number) {
			setData({
				...inputs,
				claim_number: claim_number,
			});
		}
	}, [claim_number])

	/**
	 * function to handle input changes and alter the value
	 * @param object e input object with name and value
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	function handleChange(e) {
		const { name, value } = handleInputChange(e);

		setErrors({ ...errors, ...validate(name, value, data) });
		data[name] = value;
		setData({ ...data });

		openLogoutModalOnPhoneClick(name, value);
	}

	/**
	 * function to handle submit
	 * @param object e form object
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	function handleSubmit(e) {
		e.preventDefault();
		setAction({ isSubmitted: true });
		const formErrors = {};
		for (const [name, value] of Object.entries(data)) {
			const inputError = validate(name, value, data);
			formErrors[name] = inputError[name];
		}
		let valid = validateForm(formErrors);
		if (!valid) {
			setErrors(formErrors);
			setAction({ isSubmitted: true });
			return;
		}
		dispatchClaimStatusAction();
	}


	/**
	 * function to dispatch sign in
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	async function dispatchClaimStatusAction() {
		const isClaimStatus = await checkSsnandDob(data);

		if (isClaimStatus.status === 1) {
			if (isClaimStatus.data.beneficiary.email === null && isClaimStatus.data.beneficiary.phoneNumber === null) {
				showToastMessage("Email and phone number associated with this beneficiary is not found.");
			} else {

				let mobNumber = (data.mobile_number && data.mobile_number !== '') ? data.mobile_number : isClaimStatus.data.beneficiary.phoneNumber;
				let param = {
					claim_number: claim_number,
					last_of_ssn: isClaimStatus.data.beneficiary.ssn,
					dob: isClaimStatus.data.beneficiary.dateOfBirth,
					name: isClaimStatus.data.beneficiary.firstName + " " + isClaimStatus.data.beneficiary.lastName,
					email: isClaimStatus.data.beneficiary.email,
					// mobile_number: isClaimStatus.data.beneficiary.phoneNumber,
					mobile_number: mobNumber,
					otp_mobile: data.otp_mobile,
					otp_email: data.otp_email,
				}
				const isOtp = await sendOtp(param);
				if (isOtp.status === 1) {
					navigate(`${preAuthRoutes('otp').path}`, { state: { claim_id: isClaimStatus.data.claim_id, beneficiary: isClaimStatus.data.beneficiary, claim_number: data.claim_number, otp_email: data.otp_email, otp_mobile: data.otp_mobile, mobile_number: param.mobile_number } });
					// showToastMessage(isOtp.message);
				}
			}
		}
	}

	const routeChange = () => {
		navigate(`${preAuthRoutes('claim_info').path}`);
	};

	const openLogoutModal = () => {
		setshowLogoutModal(!showLogoutModal);
	}

	
	const openLogoutModalOnPhoneClick = (name, value) => {

		if (name === 'otp_mobile') {

			if (value === true) {
				openLogoutModal();
			} else {
				setData({
					...data,
					mobile_number: '',
				});
			}
		}
	}

	const logoutModalSubmit = (phone) => {

		setData({
			...data,
			mobile_number: phone,
		});
	}

	return (
		<PreAuth>
			<Helmet>
				<title>{`${APP_NAME} | ${preAuthRoutes('authenticate').name}`}</title>
			</Helmet>
			<form onSubmit={handleSubmit} noValidate>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography variant='h5' align='center'>{`${preAuthRoutes('authenticate').name}`}</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<FormInput
							tabIndex={1}
							label='Last 4 of SSN #'
							name='last_of_ssn'
							value={data.last_of_ssn}
							error={action.isSubmitted && errors.last_of_ssn ? errors.last_of_ssn : ''}
							onChange={handleChange}
							maskType={`digit-only`}
							maxLength={4}
							autoFocus={true}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<FormDatePicker
							tabIndex={2}
							label='Date of Birth'
							name='dob'
							value={data.dob}
							error={action.isSubmitted && errors.dob ? errors.dob : ''}
							onChange={handleChange}
							minDate={new Date(1900, 0, 1)}
							disableFuture={true}
							readOnly={true}
						/>
					</Grid>
					<Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
						<FormGroup
							label="Send authorization code to:"
							error={
							action.isSubmitted &&
							(errors.otp_mobile || errors.otp_email ? "Select phone or email for authorization code" : "")
							}
						>
							<Grid container item xs={12} sm={12} md={6} lg={6} xl={6}>
								<FormCheckbox
									name="otp_mobile"
									label="Phone"
									value={data.otp_mobile}
									onChange={handleChange}
								/>
								</Grid>
							<Grid container item xs={12} sm={12} md={6} lg={6} xl={6}>
								<FormCheckbox
									name="otp_email"
									label="Email"
									value={data.otp_email}
									onChange={handleChange}
								/>
							</Grid>
						</FormGroup>
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' alignItems='center' justifyContent='space-between'>
						<SubmitButton label={`Next`} loading={claimStatusInProgress} tabIndex={3} fullWidth endIcon={<ArrowRightAltIcon />} />
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' alignItems='center' justifyContent='space-between'>
						<GeneralButton label={`Back`} onClick={routeChange} tabIndex={4} fullWidth endIcon={<WestIcon />} />
					</Grid>
				</Grid>
			</form>

			<PhoneOtpModal show={showLogoutModal} closeModal={openLogoutModal} logoutModalSubmit={logoutModalSubmit}/>

		</PreAuth>
	);
}

export { Authenticate };