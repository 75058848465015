import { postAuthRoutes, preAuthRoutes, PrivateRoute } from ".";
import { NotFound } from "../_components/layout";

function getBrowserRouter() {

    const postAuthRoutesList = postAuthRoutes();

    let postRoutes = [];
    for (const key in postAuthRoutesList) {
        if (postAuthRoutesList.hasOwnProperty(key)) {
            if (postAuthRoutesList[key].parentRoute) {
                const parentRoute = postAuthRoutesList[key].parentRoute;

                var postRouteIndex = postRoutes.findIndex(function (route) {
                    return route.slug === parentRoute;
                });

                if (!postRoutes[postRouteIndex]['children'])
                    postRoutes[postRouteIndex]['children'] = [];
                postRoutes[postRouteIndex]['children'].push({ ...postAuthRoutesList[key], slug: key, path: postAuthRoutesList[key].path.split("/").slice(2).join("/") })
            } else
                postRoutes.push({ ...postAuthRoutesList[key], slug: key })
        }
    }

    const preAuthRoutesList = preAuthRoutes();

    let preRoutes = [];
    for (const key in preAuthRoutesList) {
        if (preAuthRoutesList.hasOwnProperty(key)) {
            if (preAuthRoutesList[key].parentRoute) {
                const parentRoute = preAuthRoutesList[key].parentRoute;

                var preRouteIndex = preRoutes.findIndex(function (route) {
                    return route.slug === parentRoute;
                });

                if (!preRoutes[preRouteIndex]['children'])
                    preRoutes[preRouteIndex]['children'] = [];

                preRoutes[preRouteIndex]['children'].push({ ...preAuthRoutesList[key], slug: key, path: preAuthRoutesList[key].path.split("/").slice(2).join("/") })
            } else
                preRoutes.push({ ...preAuthRoutesList[key], slug: key })
        }
    }


    const allRoutes = ([
        {
            path: "/",
            element: <PrivateRoute />,
            children: postRoutes,
        },
        ...preRoutes,
        {
            path: '/*',
            element: <NotFound />
        }
    ])
    return allRoutes;
}

export { getBrowserRouter };
