import {
	NOTE_REQUEST, NOTE_SUCCESS, NOTE_FAILURE
} from "../actions";


const initialState = {
	NOTE_loading: false, note: {}, NOTE_errors: {}
}


export function AddNoteReducer(state = initialState, action) {
	switch (action.type) {

		

		/** begin::note redux */
		case NOTE_REQUEST: {
			return {
				NOTE_loading: true,
				note: {},
				NOTE_errors: {}
			};
		}
		case NOTE_SUCCESS: {
			return {
				NOTE_loading: false,
				note: action.data,
				NOTE_errors: {}
			};
		}
		case NOTE_FAILURE: {
			return {
				NOTE_loading: false,
				note: {},
				NOTE_errors: action.errors
			};
		}
		/** end::note redux */

		default:
			return state;
	}
}
