

import React from 'react'
import { styled } from '@mui/material'
import ReactDataTable from 'react-data-table-component';

const StyledDataTable = styled(({ ...props }) => (<ReactDataTable {...props} />))`
        & .rdt_TableCell{
            border-right-style: solid;
            border-right-width: 1px;
            border-right-color: #0000001f;
            &:first-child {
                border-left-style: solid;
                border-left-width: 1px;
                border-left-color: #0000001f;
            }
        }
        & .rdt_TableHeadRow {
            border-top-style: solid;
            border-top-width: 1px;
            border-top-color: #0000001f;
            font-weight: 900;
            font-size: 16px;
            min-height: 50px;

            & .rdt_TableCol {
                border-right-style: solid;
                border-right-width: 1px;
                border-right-color: #0000001f;
                &:first-child {
                    border-left-style: solid;
                    border-left-width: 1px;
                    border-left-color: #0000001f;
                    overflow: unset;
                }
            }
        }
        &:last-child {
            paddingBottom:${props => props.theme.spacing(4)};
        }
        & .rdt_TableRow {

            & .row-action {
                width: 100%;
                display: none;
            }
            &:hover {
                z-index: 2;
                ${'' /* boxShadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%); */}
                & .badge-status {
                    opacity: 0.1;
                }
                & .row-action {
                    & span {
                        padding: 0 2px;
                    }
                    display: flex;
                    position: absolute;
                    justify-content: center;
                }
            }
        }
`;

export { StyledDataTable }
