import { Card,  CardHeader, Grid } from '@mui/material';
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalFooterCloseBtn, ModalFooterSaveBtn } from '..'
import { validate } from './validate';
import { handleInputChange,validateForm } from '../../../_helpers';
import { FormInput } from '../../../_components/form';



function AddNoteModal({ show, showLoader, closeModal, addNote }) {

    const [noteData, setNoteData] = useState({ note: '' });
    const [action,setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [errors, setErrors] = useState({ ...noteData });

    /**
     * function to handle input changes and modify the value
     * @author Naveen
     * @created_at 21 Sept 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, noteData)) });
        noteData[name] = value;
        setNoteData({ ...noteData });
    }

    const onClose = () => {
        setNoteData({ note:'' });
        closeModal(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ isSubmitted: true });
		const formErrors = {};
		for (const [name, value] of Object.entries(noteData)) {
			const inputError = validate(name, value);
			formErrors[name] = inputError[name];
		}
		let valid = validateForm(formErrors);
		if (!valid) {
			setErrors(formErrors);
			setAction({ isSubmitted: true });
			return;
		}
        addNote(noteData);
        setNoteData({ note:'' });
    }

    return (
        <Modal isDialogOpen={show} onClose={onClose} loader={showLoader} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
            <Card style={{ boxShadow: `none`, padding: 30 }}>
            <CardHeader title='Add a note' />
                {/* <CardMedia component="img" image={questinMarkSvg} style={{ position: "absolute", padding: 0, bottom: 0, right: 0, top: 0, width: "60%", opacity: '0.1' }} /> */}
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <ModalBody style={{ padding: 0 }}>
                        <Grid container spacing={3}>
                    
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormInput
                                tabIndex={1}
                                label='Note'
                                name='note'
                                value={noteData.note}
                                error={action.isSubmitted && errors.note ? errors.note : ''}
                                onChange={handleChange}
                                autoFocus={true}
                                />
                            </Grid>
                        </Grid>
                    </ModalBody>
                    <ModalFooter style={{ padding: 0 }}>
                        <ModalFooterSaveBtn tabIndex={3} text={`Add Note`} isProcessing={action.isProcessing} />
                        <ModalFooterCloseBtn tabIndex={4} onClose={onClose} />
                    </ModalFooter>
                </form>
            </Card>
        </Modal>
    )
}

export { AddNoteModal }
