import React from "react";
import { FormControl, FormHelperText } from "@mui/material";
import { FormGroupStyle as useStyles } from ".";

const FormGroup = ({ label, info, error, children, name }) => {
  	const classes = useStyles();

	return (
		<FormControl fullWidth className={classes.formControl} error>
			<div>
				{label && <label>{label}</label>}
				<div style={{ display: "flex" }}>
					{children}
				</div>
			</div>
		{info && (
			<FormHelperText id={`helper_${name}`} className={classes.info}>
				{info}
			</FormHelperText>
		)}
		{error && (
			<FormHelperText id={`error_${name}`} error className={classes.info}>
				{error}
			</FormHelperText>
		)}
		</FormControl>
	);
};

export { FormGroup };
