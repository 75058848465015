import React from 'react'
import PropTypes from 'prop-types'
import './modalStyle.scss';
import { Dialog, Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Modal({ isDialogOpen, size, className, onClose, children, fullWidth, maxWidth, loader, ...props }) {
    return (
        <Dialog open={isDialogOpen} TransitionComponent={Transition} size={size} className={className} onClose={onClose} fullWidth={fullWidth} maxWidth={maxWidth} {...props}>
            {children}
        </Dialog>
    )
}


Modal.defaultProps = {
    isDialogOpen: "false",
    className: "",
    loader: false,
    fullWidth: true,
    maxWidth: "md"
}


Modal.propTypes = {
    isDialogOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
}

export { Modal }
