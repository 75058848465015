import React from 'react';
import { Box, styled } from '@mui/material';

const StyledPreAuth = styled(({ ...props }) => <Box {...props} />)`
	& .grid-wrapper {
		height: 100vh;
		& .MuiGrid-container {
			margin: 0;
		}
		& .left-content {
			background-color: ${(props) => props.theme.palette.primary.main};
			border-radius: 4px 0 0 4px;
			height: 100vh;
		}
		& .right-content {
			display: flex;
			align-items: center;
			justify-content: center;

			& .grid-wrapper-inner {
				& .MuiCardContent-root {
					padding-top: 48px;
					padding-bottom: 48px;
					max-width: 500px;
				}
				background: #edf4f0;
				padding: 0;
				& .MuiGrid-item {
					padding-left: 24px;
					padding-right: 48px;

					& .MuiInputBase-input {
						background: #fff;
					}
				}
			}
		}
	}
`;

export { StyledPreAuth };
