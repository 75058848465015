import React from 'react';
import { Box, Typography } from '@mui/material';
import { ShortLogo } from '../../../_components/logo/short-logo';

function Header() {
	return (
		<Box>
			<Box display={`flex`} alignItems={`center`} justifyContent={`center`} mb={2}>
				<ShortLogo style={{ fill: `#fff` }} fill='white' />
			</Box>
			<Typography variant='h1' color='#fff' align='center'>
				Life Help.
			</Typography>
			<Typography variant='h1' color='#fff' align='center'>
				When You Need It.
			</Typography>
		</Box>
	);
}

export { Header };
