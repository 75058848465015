import { UpdateBeneficiaryServices } from "../services";


export const BENEFICIARY_REQUEST = 'BENEFICIARY_REQUEST';
export const BENEFICIARY_SUCCESS = 'BENEFICIARY_SUCCESS';
export const BENEFICIARY_FAILURE = 'BENEFICIARY_FAILURE';


export const UpdateBeneficiaryAction = {
    /**
    * Action for sign in 
    * @param {Object} params - The params which are used for sending beneficiary update.
    * @param {string} params.claim_number - Claim_number of the user
    * @author Akshay 
    */
    updateBeneficiary: (params) => {
        return dispatch => {
            dispatch(request(params));
            return UpdateBeneficiaryServices.updateBeneficiary(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: BENEFICIARY_REQUEST, request: request } }
        function success(request, response) { return { type: BENEFICIARY_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: BENEFICIARY_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    
};



