import React, { useEffect, useState } from 'react';
import { Chatbot } from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css'
import { IconButton, Toolbar, Typography } from '@mui/material';
import { ChatOutlined, CloseOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';

import ChatbotMessageParser from './ChatbotMessageParser';
import ChatbotActionProvider from './ChatbotActionProvider';
import config from './chatbotConfiguration';

import './chatbot.css';

function ChatbotComponent() {

	const [isChatbotOpen, setIsChatbotOpen] = useState(false);

	const handleToggleChatbot = () => {
		setIsChatbotOpen(!isChatbotOpen);
	};


	useEffect(() => {
		return () => {
			setIsChatbotOpen(false);
		};
  	}, []);

	const CustomHeader = () => (
		<Toolbar sx={{ backgroundColor: '#efefef', minHeight: "50px !important", justifyContent: 'space-between'  }}>
			<Typography variant="h6" component="div" sx={{ flexGrow: 1, color:"#514f4f !important", textAlign: 'left'  }}>
				Message us
			</Typography>

			<IconButton  size='small' onClick={handleToggleChatbot} sx={{ color:"#514f4f !important", marginLeft: 'auto' }}>
				<CloseOutlined />
			</IconButton>
		</Toolbar>
	  );

	const chatbotConfig = {
		...config,
		customComponents: {
		  	header: CustomHeader,
		},
	};

	const validator = (input) => {
		if (!input.replace(/\s/g, '').length) //check if only composed of spaces
		  	return false;
		if (input.length > 1) //check if the message is empty
		  	return true;
		return false
	}

	const loadMessages = () => {
		const messages = JSON.parse(localStorage.getItem('bot_chat_messages'));

		return (messages && messages.messages ? messages.messages : undefined);
	};


	return (

		<div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '1300' }}>
			{isChatbotOpen ? (
				<Box className="chatbot-container chatbot-open">
					<Chatbot
						config={chatbotConfig}
						messageParser={ChatbotMessageParser}
						actionProvider={ChatbotActionProvider}
						validator={validator}
						runInitialMessagesWithHistory
						messageHistory={loadMessages()}
					/>
				</Box>
			) : (
				<IconButton onClick={handleToggleChatbot} sx={{ color: "#fff", backgroundColor:"#91BAA4", width: 56, height: 56, "&:hover": { backgroundColor: "#83a894" } }}>
					<ChatOutlined />
				</IconButton>
			)}
		</div>
	);
}

export { ChatbotComponent }