

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { FormControl, FormHelperText } from '@mui/material';
import { FormPhoneInputStyle as useStyles } from './';

function FormPhoneInput({ label, name, value, placeholder, className, onChange, tabIndex, id, error, formOpen, info, allowDropdown, ...props }) {

    const [telValue, setTelValue] = useState(value ?? '');

    const [phoneKey, setPhoneKey] = useState(1);

    const onSelectFlag = (num, country) => {
        // console.log(num, country);
    }
    const classes = useStyles();

    useEffect(() => {
        if (formOpen === 2) {
            setTelValue('')
            setPhoneKey(phoneKey + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formOpen]);



    const handlePhoneChange = (status, teliValue, countryData, number, id) => {
        setTelValue('+' + countryData.dialCode + teliValue);
        onChange(
            {
                target:
                {
                    type: 'tel2',
                    name: name,
                    value: teliValue === '' ? '' : status ? '+' + countryData.dialCode + teliValue : 'undefined',
                }
            }
        );
    }

    return (
        <FormControl fullWidth className={classes.formControl}  >
            {/* <InputLabel htmlFor={name} className={classes.label}>{label}</InputLabel> */}

            <IntlTelInput
                key={phoneKey}
                containerClassName={`intl-tel-input tel-wrapper ${classes.intlTelInputs}`}
                inputClassName={classes.inputClass}
                placeholder={label}
                defaultValue={telValue}
                fieldName={name}
                tabIndex={tabIndex}
                onPhoneNumberChange={handlePhoneChange}
                defaultCountry='us'
                separateDialCode={true}
                formatOnInit={false}
                format={false}
                onSelectFlag={onSelectFlag}
                onPhoneNumberFocus={handlePhoneChange}
                allowDropdown = {allowDropdown}
            // style={{height: '100%', width: '100%'}}
            />
            {(info) && <FormHelperText id={`helper_${name}`} className={classes.info}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error className={classes.info}>{error}</FormHelperText>}
        </FormControl>
    )
}

FormPhoneInput.defaultProps = {
    className: ""
}

FormPhoneInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
    tabIndex: PropTypes.number,
    onChange: PropTypes.func.isRequired
}

export { FormPhoneInput }

