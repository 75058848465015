import React from 'react'
import { Box, styled } from '@mui/material';
import { DRAWER_WIDTH } from '../../_helpers';

const StyledPostAuth = styled(({ ...props }) => (<Box {...props} />))`
    & main {
        flex-grow: 1;
        height: 90vh;
        margin-left: 64px;
        padding: 25px;
    }
    & main.drawer-open {
        margin-left: ${DRAWER_WIDTH}px;
    }
`;

export { StyledPostAuth }


