import React from 'react';
import './progressbar.scss';

function ProgressBar(props) {
    const content = props.posts.map((post) =>
    <div key={post.id} className={post.status}>
        <span className="is-complete"></span>
        <p>{post.title}
        <br></br>
        <span>{post.date}</span></p>
    </div>
  );

    
    return (
        <div className="hh-grayBox pt45 pb20">
                <div className="row justify-content-between">
                    {content}
                </div>
            </div>
    )
}

export { ProgressBar };
