import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';

import { AppBar, Container, Toolbar } from '@mui/material';
import { Box } from '@mui/system';

import { getPostElementNameByPath, preAuthRoutes } from '../../../routes';
import { useContext } from 'react';
import { AppNameContext } from '../../../_contexts';
import { ShortLogo } from '../../../_components/logo';
import { GeneralButton } from '../../../_components/controls';
import { Logout } from '@mui/icons-material';
import { cookieServices } from '../../../_helpers';
import { LogoutModal } from '../../../_components';

function Header() {
	const location = useLocation();
	const navigate = useNavigate();

	const appName = useContext(AppNameContext);

	const [pageTitle, setPageTitle] = useState('Loading');
	const [showLogoutModal, setshowLogoutModal] = useState(false);

	useEffect(() => {
		setPageTitle(getPostElementNameByPath(location.pathname));
	}, [location]);

	/**
	 * function to handle submit
	 * @param object e form object
	 * @author Akshay N
	 * @created_at 30 May 2022
	 */
	function logOut() {
		localStorage.removeItem("bot_chat_messages");
		cookieServices.remove('accessToken');
		navigate(`${preAuthRoutes('claim_info').path}`);
	}

	const openLogoutModal = () => {
		setshowLogoutModal(!showLogoutModal);
	}

	return (
		<React.Fragment>
			<Helmet>
				<title>{`${appName} | ${pageTitle}`}</title>
			</Helmet>
			<AppBar color='default' elevation={0} position='sticky'>
				<Container>
					<Toolbar>
						<ShortLogo />
						<Box sx={{ flexGrow: 1 }} />
						<GeneralButton color={'secondary'} label={`Logout`} endIcon={<Logout />} onClick={openLogoutModal} />
					</Toolbar>
				</Container>
			</AppBar>

			<LogoutModal show={showLogoutModal} closeModal={openLogoutModal} logout={logOut} />

		</React.Fragment>
	);
}

export { Header };
