import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { cookieServices } from '../../_helpers';
import { preAuthRoutes } from '../../routes';

import { Box, Container } from '@mui/material';

import { Header, StyledPostAuth } from '.';
import { isMobile } from 'react-device-detect';
import { ChatbotComponent } from '../../app/post-auth/chatbot/ChatbotComponent';

const PostAuth = ({ children }) => {
	let navigate = useNavigate();

	const { isDrawerOpen: open } = useSelector((state) => state.GlobalReducer);

	useEffect(() => {
		if (!cookieServices.get('accessToken')) navigate(`/${preAuthRoutes('claim_info').path}`);
		if (isMobile) navigate(`${preAuthRoutes('is_mobile').path}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<StyledPostAuth>
			<Header />
			<Box component='main' className={`${open ? 'drawer-open' : ``}`}>
				<Container>
					<Outlet />
					<ChatbotComponent/>
				</Container>
			</Box>
		</StyledPostAuth>
	);
};

export { PostAuth };
