import {
	CHAT_BOT_REQUEST, CHAT_BOT_SUCCESS, CHAT_BOT_FAILURE
} from "../actions";


const initialState = {
	chat_bot_loading: false, chat_bot: {}, chat_bot_history: [], chat_bot_errors: {}
}


export function ChatBotReducer(state = initialState, action) {
	switch (action.type) {

		
		/** begin::chat redux */
		case CHAT_BOT_REQUEST: {
			return {
				chat_bot_loading: true,
				chat_bot: {},
				chat_bot_errors: {}
			};
		}
		case CHAT_BOT_SUCCESS: {

			// let newArray = [...state.chat_bot_history]; //making a new array

			// newArray = newArray.concat(action.data);

			return {
				chat_bot_loading: false,
				chat_bot: action.data,
				// chat_bot_history: newArray,
				chat_bot_errors: {}
			};
		}
		case CHAT_BOT_FAILURE: {
			return {
				chat_bot_loading: false,
				chat_bot: {},
				chat_bot_errors: action.errors
			};
		}
		/** end::chat redux */

		default:
			return state;
	}
}
