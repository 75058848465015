import { ChatBotServices } from "../services";

export const CHAT_BOT_REQUEST = 'CHAT_BOT_REQUEST';
export const CHAT_BOT_SUCCESS = 'CHAT_BOT_SUCCESS';
export const CHAT_BOT_FAILURE = 'CHAT_BOT_FAILURE';


export const ChatBotAction = {

    /**
    * Action for sign in 
    * @param {Object} params - The params which are used for chat api.
    * @param {string} params.message - message
    * @param {string} params.history - chat history
    * @author Jasin 
    */
    chat: (params) => {
        return dispatch => {
            dispatch(request(params));
            return ChatBotServices.chat(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHAT_BOT_REQUEST, request: request } }
        function success(request, response) { return { type: CHAT_BOT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHAT_BOT_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    
};