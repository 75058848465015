import {
	BENEFICIARY_REQUEST, BENEFICIARY_SUCCESS, BENEFICIARY_FAILURE
} from "../actions";


const initialState = {
	BENEFICIARY_loading: false, beneficiary: {}, BENEFICIARY_errors: {}
}


export function UpdateBeneficiaryReducer(state = initialState, action) {
	switch (action.type) {

		

		/** begin::beneficiary status redux */
		case BENEFICIARY_REQUEST: {
			return {
				BENEFICIARY_loading: true,
				beneficiary: {},
				BENEFICIARY_errors: {}
			};
		}
		case BENEFICIARY_SUCCESS: {
			return {
				BENEFICIARY_loading: false,
				beneficiary: action.data,
				BENEFICIARY_errors: {}
			};
		}
		case BENEFICIARY_FAILURE: {
			return {
				BENEFICIARY_loading: false,
				beneficiary: {},
				BENEFICIARY_errors: action.errors
			};
		}
		/** end::beneficiary status redux */

		default:
			return state;
	}
}
