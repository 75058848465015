import React from 'react';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../_helpers';
import { preAuthRoutes } from '../../../routes';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { ShortLogo } from '../../../_components';
import Linkify from 'react-linkify';
import appStoreLogo from './../../../_theme/images/app-store-apple-logo.svg';
import googlePlayStoreLogo from './../../../_theme/images/google-play-logo.svg'
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function IsMobile() {
	let navigate = useNavigate();

	useEffect(() => {
		if (!isMobile) navigate(`${preAuthRoutes('claim_info').path}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<Helmet>
				<title>{`${APP_NAME} | ${preAuthRoutes('is_mobile').name}`}</title>
			</Helmet>
			<Box sx={{ backgroundPosition: `left`, backgroundImage: `url(${ShortLogo})` }}>
				<Stack spacing={2} direction="column" justifyContent="center" alignItems="center" style={{ height: `100vh` }} >
					<Box sx={{ height: `200px`, display: `flex`, alignItems: `flex-end`, justifyContent: `center` }}>
						<Grid container spacing={3} >
							<Grid item xs={12} display={`flex`} justifyContent={`center`} alignItems={`center`} >
								<ShortLogo width={`75`} />
							</Grid>
						</Grid>
					</Box>
					<Box sx={{ p: 3, height: `100%`, display: `flex`, alignItems: `center`, justifyContent: `center` }}>
						<Grid container spacing={3} >
							<Grid item xs={12}  >
								<Box sx={{ background: `#edf4f0`, p: 3, borderRadius: 4 }}>
									<Typography variant='body2' textAlign={'center'}>
										<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
											<a target="blank" href={decoratedHref} key={key}>
												{decoratedText}
											</a>
										)}>
											The http://benenav.tencode.com website is optimized for your laptop. Please use it there.  Alternatively, please  install the benenav app on your cell phone.
										</Linkify>
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Box>
					<Box sx={{ height: `200px` }}>
						<Grid container spacing={3} >
							<Grid item xs={6} display={`flex`} justifyContent={`right`} alignItems={`center`} >
								<img src={`${appStoreLogo}`} alt={`Apple logo`} height={`100px`} />
							</Grid>
							<Grid item xs={6} display={`flex`} justifyContent={`left`} alignItems={`center`} >
								<img src={`${googlePlayStoreLogo}`} alt={`Google play store logo`} height={`100px`} />
							</Grid>
						</Grid>
					</Box>
				</Stack>
			</Box>
		</React.Fragment>
	);
}

export { IsMobile };
