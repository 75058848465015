import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { GlobalReducer, ClaimStatusReducer, OtpReducer, AddNoteReducer, UpdateBeneficiaryReducer, ChatBotReducer } from './reducers';

const loggerMiddleware = createLogger({ predicate: () => process.env.NODE_ENV !== 'production' });
const rootReducer = combineReducers({ GlobalReducer, ClaimStatusReducer, OtpReducer, AddNoteReducer, UpdateBeneficiaryReducer, ChatBotReducer });



export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, loggerMiddleware));
