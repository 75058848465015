import React from 'react';
import { FormControl, styled } from '@mui/material';

const StyledFormDatePicker = styled(({ ...props }) => <FormControl {...props} />)`
	& .MuiFormLabel-root {
		color: ${(props) => props.theme.palette.text.primary};
	}
	& .MuiFormHelperText-root {
		margin-left: 0;
	}
`;

export { StyledFormDatePicker };
