import { useEffect, useState } from 'react';
import { cookieServices } from '../../_helpers';
import { useNavigate } from 'react-router-dom';

import { Box, Card, CardContent, Grid } from '@mui/material';
import { Header, StyledPreAuth } from '.';
import { postAuthRoutes, preAuthRoutes } from '../../routes';
import { isMobile } from 'react-device-detect';

const PreAuth = ({ children }) => {
	let navigate = useNavigate();

	useEffect(() => {
		if (cookieServices.get('accessToken')) navigate(`${postAuthRoutes('claim_details').path}`);
		if (isMobile) navigate(`${preAuthRoutes('is_mobile').path}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<StyledPreAuth justifyContent='center' alignItems='center'>
			<Box className='grid-wrapper'>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={12} md={5} lg={5} xl={5} display='flex' alignItems='center' justifyContent='center' className='left-content'>
						<Header />
					</Grid>
					<Grid item xs={12} sm={12} md={7} lg={7} xl={7} className='right-content'>
						<Card className='grid-wrapper-inner'>
							<CardContent>{children}</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</StyledPreAuth>
	);
};

export { PreAuth };
