import { apiServices } from '../../_helpers';


export const OtpServices = {

    
    /**
     * Service for claim status check 
     *
     * @parm json params checkClaimStatus parameters of {claim_number}
     * @author Drishya 
     */
    sendOtp: (params) => {

        return apiServices.post('/send-otp', params)
        .then((response) => {
             return response; 
            })
            
    },

    /**
     * Service for claim status check 
     *
     * @parm json params checkClaimStatus parameters of {claim_number}
     * @author Drishya 
     */
    verifyOtp: (params) => {

        return apiServices.post('/verify-otp', params)
        .then((response) => {
             return response; 
            })
            
    },

    
    

};
